<template>
  <div class="row">
    <div class="col-12 col-lg-10 g c">
        <div class="card card-body">
            <div class="col-12 table-responsive">
                عدد الحصص في اليوم: 
                <input type="number" v-model="total" style="width: 60px; border: solid 1px #eee">
                <table class="table table-bordered table-hover table-sm">
                    <thead>
                        <th>
                            الفصل
                        </th>
                        <th>
                            الحصص المحضرة
                        </th>
                        <th>
                            الحصص الغير محضرة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="c in classes" :key="c">
                            <td>
                                {{ c }}
                            </td>
                            <td v-if="attends[c]">
                                <button v-for="i in attends[c]" v-b-tooltip.hover.v-primary :title="names[c + ' - ' + i]" :key="i" class="btn btn-success btn-sm" style="border-radius: 0px">
                                    {{ i }}
                                </button>
                            </td>
                            <td v-if="attends[c]">
                                <template v-for="ii in Array.from({length: total}, (v, k) => k+1)">
                                    <button v-if="!attends[c].includes(ii.toString())" :key="ii" v-b-tooltip.hover.v-primary :title="names[c + ' - ' + ii]" class="btn btn-danger btn-sm" style="border-radius: 0px">
                                        {{ ii }}
                                    {{ names[c + ' - ' + ii] }}
                                    </button>
                                </template>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card card-body">
            <div class="col-12 table-responsive">
                احصائيات المعلمين
                <table class="table table-bordered table-hover table-sm">
                    <thead>
                        <th>
                            المعلم
                        </th>
                        <th>
                            الحصص المحضرة
                        </th>
                    </thead>
                    <tbody>
                        <tr v-for="c in teachers" :key="c">
                            <td>
                                {{ c }}
                            </td>
                            <td v-if="attends2[c]">
                                <button v-for="i in attends2[c]" :key="i" class="btn btn-success btn-sm" style="border-radius: 0px">
                                    {{ i }}
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { BFormCheckbox, VBModal, BModal, VBTooltip, BTooltip, } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        BFormCheckbox,
        BTooltip,
        BModal
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
        "b-modal": VBModal,
    },
    data(){
        return {
            user: JSON.parse(localStorage.getItem('user')),
            reports: {},
            date: new Date().toISOString().split("T")[0],
            classes: [],
            attends: {},
            total: 7,
            teachers: [],
            attends2: {},
            names: {}
        }
    },
    created(){
        var g = this;
        if(!checkPer("lessons")){
            this.$router.push('/NotPermitted')
            return false;
        }
        $.post(api + '/user/students/classes', {
            jwt: this.user.jwt
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                r.response.forEach(function(a){
                    a.classrooms.forEach(function(b){
                        g.classes.push(a.title + ' - ' + b)
                    })
                })

                $.post(api + '/user/lessons/report', {
                    jwt: g.user.jwt,
                    classroom: "",
                    date: g.date
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response, 200)
                    }else{
                        r.response.forEach(function(a){
                            if(!g.attends[a.classname + ' - ' + a.classroom]){
                                g.attends[a.classname + ' - ' + a.classroom] = []
                            }
                            a.attend.forEach(function(b){
                                if(!g.teachers.includes(b.teacher)){
                                    g.teachers.push(b.teacher)
                                }
                                if(!g.attends2[b.teacher]){
                                    g.attends2[b.teacher] = [];
                                }
                                if(!g.attends2[b.teacher].includes(a.classname + ' - ' + a.classroom + ' الحصة ' + b.lesson)){
                                    g.attends2[b.teacher].push(a.classname + ' - ' + a.classroom + ' الحصة ' + b.lesson)
                                }
                                g.names[a.classname + ' - ' + a.classroom + ' - ' + b.lesson] = b.teacher;
                                if(!g.attends[a.classname + ' - ' + a.classroom].includes(b.lesson)){
                                    g.names[a.classname + ' - ' + a.classroom + ' - ' + b.lesson] = b.teacher;
                                    g.attends[a.classname + ' - ' + a.classroom].push(b.lesson)
                                }
                            })
                            a.ghiab.forEach(function(b){
                                if(!g.teachers.includes(b.teacher)){
                                    g.teachers.push(b.teacher)
                                }
                                if(!g.attends2[b.teacher]){
                                    g.attends2[b.teacher] = [];
                                }
                                if(!g.attends2[b.teacher].includes(a.classname + ' - ' + a.classroom + ' الحصة ' + b.lesson)){
                                    g.attends2[b.teacher].push(a.classname + ' - ' + a.classroom + ' الحصة ' + b.lesson)
                                }
                                    g.names[a.classname + ' - ' + a.classroom + ' - ' + b.lesson] = b.teacher;
                                if(!g.attends[a.classname + ' - ' + a.classroom].includes(b.lesson)){
                                    g.attends[a.classname + ' - ' + a.classroom].push(b.lesson)
                                }
                            })
                        })
                        g.attends = JSON.parse(JSON.stringify(g.attends))
                    }
                }).fail(function(){
                })
            }
        }).fail(function(){
        })
    }
}
</script>

<style>

</style>